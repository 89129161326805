
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { apiUploadFile } from '../services/tpsApi/tpsApiImportByFile';
//import { apiCompanyGetListName } from '@/services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '@/services/tpsApi/tpsApiBrand';
import DatepickerLite from "vue3-datepicker-lite";

@Options({
  components: {
    DatepickerLite
  },
  emits: [
      "form",
      "alert",
      "search"
  ]
})

export default class AnnouncementSearchMenu extends Vue {
  $refs!: {
        file
  }

  @Prop({ required: false }) create: boolean = true;
  @Prop({ required: false }) table: string = "";

  file: string | Blob = "";
  filter_keyword: string = "";
  //filter_company: string = "";
  filter_brand: string = "";
  WRITE: number | undefined = undefined; 


  //companySelectOptions: {} = {};
  brandSelectOptions: {} = {};


  async beforeMount() {
        this.WRITE = this.$store.getters.write;
  }
  
  @Emit("form")
  showForm(): { actionType: string, ID: number | null } {
      return { actionType: "create", ID: null };
  }

  @Emit("alert")
  doAlert(pAlertType, pMsg): {alertType: string, msg: string} {
    return {alertType: pAlertType, msg: pMsg};
  }

    @Emit("export")
  exportFile() {
    return { output: "xlsx" }
  }



  @Emit("search") 
  search() {
    return {    filter_keyword: this.filter_keyword,
                //filter_company: this.filter_company,
                filter_brand: this.filter_brand };
  }

  async mounted() {
    //this.companySelectOptions = await apiCompanyGetListName();
    this.brandSelectOptions = await apiBrandGetListName();
  }
  
  importFile() {
    this.$refs.file.value = null;
    this.$refs.file.click();
  }
  
  async fileUpload(event) {
    this.file = this.$refs.file.files[0];
    let formData = new FormData();
    formData.append('file', this.file);
    console.log("file:",this.file);
    console.log("table:",this.table);
    const res = await apiUploadFile({table:this.table, data:formData});
    if(res.msg === ("ok")) this.doAlert("success", "上傳成功");
  }
  

  
}
