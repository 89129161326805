
import { Options, Vue } from 'vue-class-component';
import { apiPaymentRuleGetList,
         apiPaymentRuleGetListTotalNum } from '../services/tpsApi/tpsApiPaymentRule';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { IPaymentRuleList } from '../services/tpsApi/models/paymentRule';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import PaymentRuleSearchMenu from '@/components/PaymentRuleSearchMenu.vue';
import PaymentRulesForm from '@/components/PaymentRulesForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    Table,
    PaymentRuleSearchMenu,
    PaymentRulesForm,
    Pagination
  },
})
export default class Paymentrule extends Vue {
    $refs!: {
        form: PaymentRulesForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        version:"版本號",
        name: "說明",
        brand_id: "品牌名稱",
        modified_user: "最後執行人員",
        modified_time: "最後執行時間",
        invalid: "狀態"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    PaymentRuleList: IPaymentRuleList | Blob = [];
    filter: string | undefined = undefined;
    filter_brand: number | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiPaymentRuleGetListTotalNum({filter: this.filter,filter_brand: this.filter_brand});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter,
                                        filter_brand: this.filter_brand});
        this.PaymentRuleList = await apiPaymentRuleGetList(pData);
        console.log(this.PaymentRuleList);
    } 
    
    // async download(pData: { output: string }) {
    //     const res = await apiPaymentRuleGetList({output: pData.output, filter: this.filter});
    //     exportFile({ fileName: "付款參數", data: res });
    // }

    doSearch(pData: {filter: string , filter_brand: number}) {
        this.filter = pData.filter;
        this.filter_brand = pData.filter_brand;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
